<template>
  <div>
    <el-card class="box-card card-contanier">
      <div class="card-content">
        <el-form :inline="true" @submit.native.prevent>
          <el-row :gutter="20">
            <el-col :span="16">
              <el-form-item>
                <el-input
                  v-model="searchForm.vcard_name"
                  size="medium"
                  placeholder="会员卡名称"
                  clearable
                  @keyup.enter.native="search"
                ></el-input>
              </el-form-item>
              <!--<el-form-item>
                <el-input v-model="searchForm.typeDesc" size="medium" placeholder="会员卡号" clearable></el-input>
              </el-form-item>-->

              <el-form-item>
                <el-button
                  @click="search"
                  @keyup.enter.native="search"
                  size="medium"
                  >搜索</el-button
                >
              </el-form-item>
              <el-form-item>
                <el-button @click="refresh" icon="el-icon-refresh" size="medium"
                  >重置</el-button
                >
              </el-form-item>
            </el-col>
            <el-col :span="8" style="text-align: right">
              <el-form-item>
                <el-button
                  type="primary"
                  size="medium"
                  @click="addVipCard"
                  v-if="hasAuth('sy:vip:add')"
                  >新增</el-button
                >
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </el-card>

    <el-table
      ref="multipleTable"
      :data="tableData"
      v-loading="isLoading"
      tooltip-effect="dark"
      border
      style="width: 100%"
      stripe
      size="small"
      :row-style="{ height: '10px' }"
      :cell-style="{ padding: '1px 0' }"
      @selection-change="handleSelectionChange"
    >
      <!--<el-table-column type="selection" width="55" align="center"></el-table-column>-->
      <el-table-column
        type="index"
        label="序号"
        :index="indexMethod"
        align="center"
        fixed
      />
      <el-table-column
        prop="vcardName"
        label="会员卡名称"
        width="200"
        align="center"
        fixed
      ></el-table-column>
      <el-table-column
        prop="vcardTitle"
        label="卡副标题"
        width="200"
        show-overflow-tooltip
        align="center"
      ></el-table-column>
      <el-table-column label="有效期" width="100" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.vcardVil === 'DATE_TYPE_PERMANENT'">
            <el-tag type="success" size="small">永久有效</el-tag>
          </span>
        </template>
      </el-table-column>
      <el-table-column
        prop="vcardPhone"
        width="150"
        label="客服电话"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="alName"
        label="所属商盟"
        width="250"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="mgName"
        label="所属商户"
        width="250"
        align="center"
      ></el-table-column>
      <!-- <el-table-column prop="vcardDiff" label="卡类型" show-overflow-tooltip align="center">
        <template slot-scope="scope">
          <span>
            <el-tag v-if="scope.row.vcardDiff === 0" type="success" size="small" >商盟卡</el-tag>
            <el-tag v-if="scope.row.vcardDiff === 1"  size="small" >商户卡</el-tag>
          </span>
      </template>
      </el-table-column> -->
      <el-table-column label="状态" align="center" width="100">
        <template slot-scope="scope">
          <span>
            <el-tag
              v-if="scope.row.vcardStat === '1'"
              type="success"
              size="small"
              effect="dark"
              >已启用</el-tag
            >
            <el-tag
              v-if="scope.row.vcardStat === '2'"
              type="danger"
              size="small"
              effect="dark"
              >已禁用</el-tag
            >
          </span>
        </template>
      </el-table-column>
      <el-table-column label="创建时间" width="180" align="center">
        <template slot-scope="scope">
          {{ scope.row.vcardDm ? scope.row.vcardDm.replaceAll("T", " ") : "" }}
        </template>
      </el-table-column>
      <el-table-column
        prop="icon"
        width="180px"
        label="操作"
        align="center"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button type="text" @click="creatQrcode(scope.row)"
            >二维码</el-button
          >
          <el-divider direction="vertical"></el-divider>
          <el-button type="text" @click="edit(scope.row)">修改</el-button>
          <!-- <el-divider direction="vertical"></el-divider>
          <el-button type="text" @click="putCard(scope.row.vcardId)">投放会员卡</el-button> -->
          <!--TODO 是否不允许重复投放-->
          <el-divider
            direction="vertical"
            v-if="hasAuth('sy:vip:disable')"
          ></el-divider>
          <!--<el-button type="text" @click="deleteCard(scope.row.vcardId)">删除</el-button>-->
          <el-button
            v-if="hasAuth('sy:vip:disable')"
            type="text"
            @click="forbiddenCard(scope.row)"
            :disabled="scope.row.vcardStat === '2'"
          >
            <span>
              <span v-if="scope.row.vcardStat === '1'" style="color: #ff4949"
                >禁用</span
              >
              <span v-if="scope.row.vcardStat === '2'" style="color: gray"
                >启用</span
              >
            </span>
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <div class="block">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="current"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>
    <el-dialog
      title="会员卡投放"
      :visible.sync="tfCardDialogVisible"
      width="600px"
      :close-on-click-modal="false"
    >
      <el-form
        :model="tfForm"
        :rules="tfFormRules"
        ref="tfForm"
        label-width="100px"
        class="demo-tfForm"
      >
        <el-form-item label="" prop="putType">
          <el-radio-group v-model="tfForm.putType">
            <el-radio label="1">
              直接发放卡券
              <div class="tf-card-hint">通过公众号直接投放</div> </el-radio
            ><br />
            <el-radio label="2" class="tf-card-radio">
              嵌入图文消息
              <div class="tf-card-hint">
                将卡券嵌入图文消息，再由公众号投放
              </div> </el-radio
            ><br />
            <el-radio label="3" class="tf-card-radio">
              下载二维码
              <div class="tf-card-hint">
                下载二维码，将二维码制成图片形式，便于统计开卡门店
              </div> </el-radio
            ><br />
            <el-radio label="4" class="tf-card-radio">
              下载链接
              <div class="tf-card-hint">
                适用于支付即会员，或者小睿洗车这种扫码洗车
              </div>
            </el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="tfSubmit('tfForm')">保存</el-button>
        <el-button @click="cancelTf('tfForm')">取消</el-button>
      </div>
    </el-dialog>

    <el-dialog
      :title="codetitle"
      :visible.sync="qrcodeDialog"
      width="450px"
      :close-on-click-modal="false"
      :before-close="cancelQrcode"
    >
      <div v-loading="qrcodeDialogLoading" class="qrcode">
        <qrcode :text="qrcodeUrl" :size="300" ref="qrcode"></qrcode>
      </div>
      <div slot="footer" class="dialog-footer" style="text-align: center">
        <el-button type="primary" @click="copyAddressUrl()">复制链接</el-button>
        <el-button @click="downQRImg()">点击下载</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import api from "../../api/vipuser";
import userApi from "../../api/user";
export default {
  name: "Vipuser",
  data() {
    return {
      ranks: "",
      tfVcardId: null,
      tfForm: {},
      tfFormRules: {
        putType: [
          { required: true, message: "请选择投放方式", trigger: "blur" },
        ],
      },
      tfCardDialogVisible: false,
      isLoading: false,
      searchForm: {
        vcard_name: "",
      },
      delBtlStatu: true,
      total: 10,
      size: 10,
      current: 1,
      tableData: [],
      dialogVisible: false,
      editForm: {},
      editFormRules: {
        cdType: [{ required: true, message: "请输入类别", trigger: "blur" }],
        typeDesc: [
          { required: true, message: "请输入类别描述", trigger: "blur" },
        ],
        cdCode: [
          { required: true, message: "请选输入代码直", trigger: "blur" },
        ],
        orderNo: [
          { required: true, message: "请选输入排序字段", trigger: "blur" },
        ],
        codeDesc: [
          { required: true, message: "请选输入代码描述", trigger: "blur" },
        ],
      },
      codetitle: "",
      qrcodeDialog: false,
      qrcodeUrl: "",
      qrcodeDialogLoading: false,
      mgId: "",
      type: "MERCHANTS",
    };
  },
  created() {
    this.getVipList();
    userApi.userinfo().then((res) => {
      this.ranks = res.data.data.result.ranks;
    });
  },
  methods: {
    indexMethod(index) {
      index = index + 1 + (this.current - 1) * this.size;
      return index;
    },
    creatQrcode(row) {
      this.codetitle = "商户名称：" + row.mgName;
      this.qrcodeDialog = true;
      this.qrcodeDialogLoading = true;
      this.mgId = row.vcardMeId;
      this.qrcodeUrl = `http://shang.xiaoruizhixi.com/qrcode-boot/api/test/pushactivecards/${this.type
        .concat("-")
        .concat(this.mgId)}`;
      this.qrcodeDialogLoading = false;
    },
    cancelQrcode() {
      this.qrcodeDialog = false;
      this.qrcodeDialogLoading = false;
    },
    copyAddressUrl() {
      let oInput = document.createElement("input");
      //这边为链接地址this.liveLink='www.baidu.com'
      oInput.value = `http://shang.xiaoruizhixi.com/qrcode-boot/api/test/pushactivecards/${this.type
        .concat("-")
        .concat(this.mgId)}`;
      document.body.appendChild(oInput);
      oInput.select();
      console.log(oInput.value);
      document.execCommand("Copy");
      oInput.remove();
      this.$message({
        message: "复制成功",
        type: "success",
      });
    },
    // 点击触发下载二维码
    downQRImg() {
      const codeurl = this.$refs.qrcode.$el.src;
      let a = document.createElement("a");
      let event = new MouseEvent("click");
      a.download = this.codetitle;
      a.href = codeurl;
      a.dispatchEvent(event);
    },
    edit(row) {
      this.$router.push({
        path: "/vip/vcard",
        query: {
          vcardId: row.vcardId,
          vcardMeId: row.vcardMeId,
        },
      });
    },
    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    refresh() {
      this.searchForm = {};
      this.current = 1;
      this.getVipList();
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
      this.slchange = val.length;
    },
    search() {
      this.current = 1;
      this.getVipList();
    },
    getVipList() {
      this.isLoading = true;
      api
        .list({
          params: {
            vcard_name: this.searchForm.vcard_name,
            current: this.current,
            size: this.size,
          },
        })
        .then((res) => {
          this.tableData = res.data.data.result.records;
          this.size = res.data.data.result.size;
          this.current = res.data.data.result.current;
          this.total = res.data.data.result.total;
          this.isLoading = false;
        });
    },
    addVipCard() {
      this.$router.push({ path: "/vip/vcard" });
    },
    cancelTf(formName) {
      this.resetForm(formName);
      this.tfForm = {};
      this.tfCardDialogVisible = false;
    },
    resetForm(formName) {
      if (this.$refs[formName]) {
        this.$refs[formName].resetFields();
      }
    },
    tfSubmit(form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          api
            .putCard({
              putType: this.tfForm.putType,
              vcardId: this.tfVcardId,
            })
            .then((res) => {
              if (res.data.code === 200) {
                this.$notify({
                  title: "prefect",
                  message: "操作成功",
                  type: "success",
                  position: "bottom-right",
                });
              } else {
                this.$notify({
                  title: "prefect",
                  message: "操作失败",
                  type: "error",
                  position: "bottom-right",
                });
              }
              this.tfCardDialogVisible = false;
              this.resetForm("tfForm");
              this.getVipList();
            });
        } else {
          return false;
        }
      });
    },
    handleSizeChange(val) {
      this.size = val;
      this.getVipList();
    },
    handleCurrentChange(val) {
      this.current = val;
      this.getVipList();
    },
    putCard(vcardId) {
      this.tfVcardId = vcardId;
      this.tfCardDialogVisible = true;
    },
    deleteCard(vcardId) {
      this.$confirm(
        "注意：如用户在商家删除卡券前已领取一张或多张该卡券依旧有效。即删除卡券不能删除已被用户领取，保存在微信客户端中的卡券, 是否确认删除？",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          api.deleteVcardById(vcardId).then((res) => {
            if (res.data.code === 200) {
              this.$notify({
                title: "prefect",
                message: "操作成功",
                type: "success",
                position: "bottom-right",
              });
            } else {
              this.$notify({
                title: "prefect",
                message: "操作失败",
                type: "error",
                position: "bottom-right",
              });
            }
            this.getVipList();
          });
        })
        .catch(() => {});
    },
    forbiddenCard(row) {
      let vcardId = row.vcardId;
      let vcardStat = row.vcardStat;
      let vcardDiff = row.vcardDiff;
      let vcardMeId = row.vcardMeId;
      let vcardAliId = row.vcardAliId;
      this.$confirm(
        "是否确认" +
          (vcardStat === "1" ? "禁用" : "启用") +
          row.vcardName +
          "？",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          api
            .forbiddenVcardById({
              vcardId,
              vcardStat,
              vcardDiff,
              vcardMeId,
              vcardAliId,
            })
            .then((res) => {
              if (res.data.code === 200) {
                this.$notify({
                  title: "prefect",
                  message: res.data.message,
                  type: "success",
                  position: "bottom-right",
                });
              } else {
                this.$notify({
                  title: "prefect",
                  message: "操作失败",
                  type: "error",
                  position: "bottom-right",
                });
              }
              this.getVipList();
            });
        })
        .catch(() => {});
    },
  },
};
</script>

<style scoped>
.el-pagination {
  margin-top: 20px;
  text-align: right;
}
.card-contanier {
  margin-bottom: 5px;
}
.tf-card-hint {
  color: #999;
  margin-top: 5px;
  margin-left: 25px;
}
.tf-card-radio {
  margin-top: 15px;
}
.qrcode {
  align-items: center;
  text-align: center;
}
</style>
